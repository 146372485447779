<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
          <!-- <box-regular username="Controllers"></box-regular> -->
        </v-col>
      </v-row>
    </v-card>

    <div class="common-container">
      <v-form>
        <v-row
          class="
            ml-0
            mr-4
            mt-4
            d-inline-flex
            justify-space-between
            align-center
          "
          style="width: 100%"
        >
          <v-col cols="12" md="2" v-if="locations">
            <v-select
              :items="locations"
              label="Location"
              v-model="selectedLocation"
              :item-text="`description`"
              :item-value="`id`"
              @change="handleChange"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="2" v-if="status">
            <v-select
              :items="status"
              label="Status"
              v-model="selectedStatus"
              :item-text="`name`"
              :item-value="`value`"
              @change="handleChange"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="2" v-if="status">
            <v-select
              :items="killFilter"
              :label="$t('alarm.filter_kill')"
              v-model="isFilterKill"
              :item-text="`name`"
              :item-value="`value`"
              @change="handleChange"
              dense
              outlined
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2"> </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :rules="rules"
              type="number"
              :label="$t(`alarm.enter_days`)"
              v-model="selectedDay"
              :value="selectedDay"
              :prefix="$tc(`alarm.days`, selectedDay)"
              @input="handleChange"
              solo
              outlined
              dense
              flat
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <section class="pl-3">
        <h2 v-if="alarms">
          {{ $tc("cycle.pallet", alarms.length) }}
        </h2>
        <div v-if="alarms.length > 0">
          <v-data-table
            :headers="headers"
            :items="alarms"
            :items-per-page="5"
            class="elevation-0"
            :item-class="kill_classes"
            :hide-default-footer="alarms.length < 5"
          >
            <template v-slot:[`item.box_id`]="props">
              <router-link
                :to="{
                  name: 'alarm-box-detail',
                  params: { id: props.item.box_id },
                }"
              >
                {{ props.item.box_id }}
              </router-link>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text small>
                <img
                  style="cursor: pointer"
                  src="@/assets/images/Icon-download.svg"
                  @click="downloadCsv(item.box_id)"
                />
              </v-btn>
              <v-btn
                text
                color="error"
                class="mx-3"
                small
                @click="confirmDeleteItem(item)"
              >
                <v-icon color="error">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </section>
    </div>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export default {
  name: "alarm",
  components: { ConfirmDialog },
  async mounted() {
    await this.fetchLocation();
    await this.fetchAlarms(this.params);
  },
  data() {
    return {
      selectedLocation: null,
      selectedDay: null,
      status: [
        { name: i18n.t("cycle.status.positioned"), value: 1 },
        { name: i18n.t("cycle.status.in_transit"), value: 2 },
      ],
      killFilter: [
        { name: i18n.t("common.true"), value: "True" },
        { name: i18n.t("common.false"), value: "False" },
      ],
      isFilterKill: null,
      params: {},
      selectedStatus: null,
      rules: [
        (v) => v > 0 || this.$t("alarm.error.min"),
        (v) => v <= 500 || this.$t("alarm.error.max"),
      ],
      headers: [
        {
          text: i18n.t("cycle.table.id"),
          align: "start",
          sortable: false,
          value: "box_id",
        },
        { text: i18n.t("cycle.table.gtin"), value: "box" },
        { text: i18n.t("cycle.table.area"), value: "area" },
        { text: i18n.t("cycle.table.location"), value: "location" },
        { text: i18n.t("cycle.table.date"), value: "date" },
        { text: i18n.t("cycle.table.cycle"), value: "cycles" },
        { text: i18n.tc("label.action", 2), value: "action" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchLocation: "dashboard/fetchLocation",
      fetchAlarms: "alarms/fetchAlarms",
      downloadCsv: "cycles/downloadCsv",
      deleteCycle: "cycles/deleteCycle",
    }),
    kill_classes(item) {
      console.log("item.kill: ", item.kill);
      if (item.kill) {
        return "kill-class";
      }
    },
    createParams() {
      const params = {};
      params["area1__type"] = this.selectedStatus;
      params["area1__location__id"] = this.selectedLocation;
      params["days"] = this.selectedDay;
      params["box__kill"] = this.isFilterKill;
      this.params = params;
    },
    handleChange() {
      this.createParams();
      this.fetchAlarms(this.params);
    },
    async confirmDeleteItem(item) {
      if (
        await this.$refs.confirm.open(
          `${i18n.t("label.delete")}`,
          `${i18n.t("user.delete-message", { item: item.box })}`,
          this.options
        )
      ) {
        this.deleteRecord(item.box);
      }
    },
    deleteRecord(id) {
      this.deleteCycle(id)
        .then(() => {
          this.fetchAlarms();
        })
        .catch((err) => console.log("Delete user error :", err));
    },
  },
  computed: {
    ...mapGetters({
      locations: "dashboard/getLocations",
      alarms: "alarms/getAlarms",
    }),
  },
};
</script>

<style></style>
